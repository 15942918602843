.wrapper {
  min-height: 40px;
  padding: var(--mod-0-point-5-multiplier);
  margin-right: var(--mod-0-point-5-multiplier);
  cursor: pointer;
  border-radius: var(--defaults-borderRadius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  background-color: var(--colors-grey-darker2);
}
.citationListName {
  margin-top: 4px;
}
.wrapper:hover:not(.selected),
.wrapper:focus:not(.selected) {
  background-color: var(--colors-grey-darker);
}
.dropdownListContainer {
  display: none;
  background-color: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  box-shadow: var(--defaults-shadow-large);
  overflow: hidden;
}
.dropdownListContainerActive {
  display: block;
  position: absolute;
  top: var(--mod-5-multiplier);
  z-index: 2;
  overflow-y: scroll;
  max-height: 475px;
}
.sharedText {
  margin-top: 10px;
  margin-bottom: 10px;
}
.showListsIcon {
  background: transparent;
  border: 0;
  color: var(--brand-light);
  margin: var(--mod-0-point-5-multiplier);
  margin-left: calc(-1 * var(--mod-0-point-5-multiplier));
  cursor: pointer;
}
.selected {
  background-color: var(--colors-grey-darker);
}
.citationListNameWrapper {
  margin-top: -2px;
}
.sharedListSvgWrapper {
  margin-right: 8px;
  display: flex;
  align-items: center;
}
.nameSelected {
  cursor: text;
}
.nameFixed {
  border: 0;
  color: var(--colors-white);
  background: transparent;
  padding-left: var(--mod-1-multiplier);
  padding-right: var(--mod-1-multiplier);
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.nameFixed.editing {
  display: none;
}
.nameInputWrapper {
  display: flex;
  background-color: var(--colors-grey-darker);
}
.nameInputWrapper .nameInput {
  background-color: var(--colors-grey-darker) !important;
  padding: 0 3px;
  margin-right: 5px;
  line-height: 32px;
  color: var(--colors-white);
  font-size: var(--text-label-small-size);
}
.dropdownList {
  padding: 7px 0 7px 7px; /* we don't add padding-right b/c scrollbar takes up that space */
  border: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 400px;
}
.dropdownListItem {
  display: flex;
  background: transparent;
  border: 0;
  width: 100%;
  cursor: pointer;
  min-height: 44px;
  padding: var(--mod-0-point-5-multiplier) var(--mod-2-multiplier);
  align-items: center;
  justify-content: space-between;
}

.dropdownListItem button {
  border: 0;
  width: 100%;
  color: black;
  white-space: nowrap;
  appearance: none;
  background: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}

.dropdownListItemCurrent {
  background: var(--colors-purple-lighter);
}

.dropdownListItem:hover {
  background-color: var(--colors-grey-lightest);
}

.dropdownListItemLine {
  margin: 0.5px 0;
  width: 100%;
  margin: 0 auto;
  border: 0;
  border-top: 1px solid var(--colors-grey-light);
}

.newCitationList {
  background-color: var(--colors-purple-lighter);
  border-radius: var(--defaults-borderRadius-small);
  margin-top: 7px;
}

.newCitationList:hover {
  background-color: #d0b7f9;
}
.newCitationListBtn {
  border: 0;
  font-size: var(--text-label-small-size);
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: var(--text-label-weight);
}

.newCitationListIcon {
  display: block;
  margin-right: var(--mod-1-multiplier);
  width: var(--mod-2-multiplier);
  height: var(--mod-2-multiplier);
  padding-top: 2px;
}

.newCitationListText {
  color: var(--colors-purple-dark);
}

/* 
  On mac machines, scrollbars only appears when interacted with.
  We override this to clearly show users that the dropdown is scrollable.
  */
.dropdownListContainer::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.dropdownListContainer::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}
