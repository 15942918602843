.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}

.confirmationModalContent {
  font-size: 12px;
}

.confirmationTitleCenter {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 21px;
}

.deleteConfirmationWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.confirmationModalOverlay {
  background-color: rgba(22, 27, 29, 0.5);
}

.confirmationModal {
  border-radius: 12px;
  padding: 30px 50px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  max-width: 620px;
}
