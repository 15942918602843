.wrapper {
  min-height: 40px;
  padding: var(--mod-0-point-5-multiplier);
  margin-right: var(--mod-0-point-5-multiplier);
  cursor: pointer;
  border-radius: var(--defaults-borderRadius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  background-color: var(--colors-grey-darker2);
}

.wrapper:hover:not(.selected),
.wrapper:focus:not(.selected) {
  background-color: var(--colors-grey-darker);
}

.dropdownListContainer {
  display: none;
  background-color: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  box-shadow: var(--defaults-shadow-large);
  overflow: hidden;
}
.dropdownListContainerActive {
  display: block;
  position: absolute;
  top: var(--mod-5-multiplier);
  z-index: 2;
}
.deleteIcon {
  cursor: pointer;
}
.optionsIcon {
  background: transparent;
  border: 0;
  color: var(--brand-light);
  cursor: pointer;
}
.selected {
  background-color: var(--colors-grey-darker);
}

.name {
  margin-top: -2px;
}
.nameSelected {
  cursor: text;
}
.nameFixed {
  border: 0;
  color: var(--colors-white);
  background: transparent;
  padding-left: var(--mod-1-multiplier);
  padding-right: var(--mod-1-multiplier);
}
.nameFixed.editing {
  display: none;
}
.nameInputWrapper {
  display: flex;
  background-color: var(--colors-grey-darker);
}
.nameInput {
  background-color: var(--colors-grey-darker);
  padding: 0 3px;
  margin-right: 5px;
  line-height: 32px;
  color: var(--colors-white);
  font-size: var(--text-label-small-size);
}

.dropdownList {
  margin: 0;
  padding: 7px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 125px;
}
.dropdownListItem {
  border-radius: 3px;
}
.dropdownListItem button {
  border: 0;
  width: 100%;
  color: black;
  white-space: nowrap;
  appearance: none;
  background: none;
  padding: 10px;
  text-align: left;
  cursor: pointer;
}
.dropdownListItem button:hover {
  background: var(--colors-purple-lighter);
  color: var(--colors-purple-medium);
}
.dropdownListItemDelete button {
  color: var(--colors-red-medium);
}
.dropdownListItemDelete:hover button {
  color: var(--colors-red-medium);
  background: var(--colors-red-light);
}
.dropdownListItemLine {
  margin: 0.5px 0;
  width: 100%;
  margin: 0 auto;
  border: 0;
  border-top: 1px solid var(--colors-grey-light);
}
