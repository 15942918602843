.button {
  font-size: var(--text-label-medium-size);
  font-weight: var(--text-label-weight);
  letter-spacing: 0.025em;
  font-family: var(--text-family);
  color: var(--colors-grey-darker);
  border-radius: var(--defaults-borderRadius-small);
  text-align: center;
  background: var(--colors-grey-light);
  border: 0;
  padding: 14px 1.2em;
  display: inline-block;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
}
.button.width {
    width: var(--props-width);
}
.button.fullWidth {
    width: 100%;
}
.button.autoWidth {
    width: auto;
    flex: none;
}
.button:hover {
  background: #d5d2e0;  /* darken(colors.grey.light, 5) */
  text-decoration: none;
}
.button:focus {
  box-shadow: var(--defaults-focus-outline);
}
.button.stretch {
  padding: 2px 15px;
  font-size: var(--text-label-small-size);
}
.button.tiny {
  padding: 5px 7px;
  font-size: var(--text-label-small-size);
  border-radius: var(--defaults-borderRadius-tiny);
}
.button.small {
  padding: 7px 10px;
  font-size: var(--text-label-small-size);
  border-radius: var(--defaults-borderRadius-tiny);
}
.button.small.circle {
  border-radius: 50%;
}
.button.medium {
  padding: 10px 15px;
}
.button.big {
  font-size: var(--text-label-medium-size);
}
.button.brand {
  color: var(--colors-white);
  background: var(--brand-default);
  box-shadow: var(--defaults-shadow-medium);
}
.button.brandDark {
  color: var(--colors-white);
  background: var(--brand-strong);
}
.button.brand:hover {
  background: var(--brand-strong);
}
.button.brand:focus {
  background: var(--brand-strong);
  box-shadow: var(--defaults-shadow-medium), var(--defaults-focus-outline);
}
.button.white {
  background: var(--colors-white);
  color: var(--colors-purple-medium);
  box-shadow: var(--defaults-shadow-medium);
}
.button.white:hover,
.button.white:focus {
  background: var(--colors-grey-lighter);
  box-shadow: var(--defaults-shadow-medium);
}
.button.default {
  color: var(--colors-purple-medium);
  background: var(--colors-purple-lighter);
}
.button.default:hover,
.button.default:focus {
  background: #d5d2e0;
}
.button.default:focus {
  box-shadow: var(--defaults-shadow-medium), var(--defaults-focus-outline);
}
.button.light {
  color: var(--content-strong);
  background: var(--background-medium);
}
.button.light:hover,
.button.light:focus {
  background: var(--background-light);
}
.button.doc {
  color: var(--colors-blue-doc);
  background: var(--colors-blue-light);
}
.button.doc:hover {
  background: #daecfd; /* darken(colors.blue.light, 4) */
}
.button.lighter {
  color: var(--colors-grey-darkest);
  background: var(--colors-grey-lighter);
}
.button.lighter:hover {
  background: var(--colors-grey-light);
}
.button.danger {
  color: var(--colors-red-medium);
  background: var(--colors-red-light);
  box-shadow: none;
}
.button.danger:hover,
.button.danger:focus {
  background: #fde6e6; /* darken(colors.red.light, 2) */
}
.button.warning {
  color: var(--colors-white);
  background: var(--colors-red-medium);
  box-shadow: none;
}
.button.warning:hover,
.button.warning:focus {
  background: #f25050; /* darken(colors.red.medium, 4) */
}
.button.link {
  background: transparent;
  color: var(--colors-grey-darkest);
}
.button.link:hover,
.button.link:focus {
  color: var(--colors-purple-medium);
}
.clean,
.clean:hover {
  text-decoration: none;
  font-weight: inherit;
}
.button[disabled],
.button[disabled]:hover,
.button[disabled]:active {
  cursor: auto;
  background: var(--colors-grey-light);
}
.button.disabled {
  background: var(--background-medium);
  cursor: not-allowed;
  color: var(--content-light);
}
.button.disabled,
.button.disabled:hover,
.button.disabled:active {
  box-shadow: none;
  background: var(--brand-default);
  opacity: 0.2;
}
