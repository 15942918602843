.paragraphMedium {
  font-size: var(--text-paragraph-medium-size);
  line-height: var(--text-paragraph-medium-line-height);
  font-weight: var(--text-paragraph-weight);
}

.paragraphSmall {
  font-size: var(--text-paragraph-small-size);
  line-height: var(--text-paragraph-small-line-height);
  font-weight: var(--text-paragraph-weight);
}

.labelMedium {
  font-weight: var(--text-label-weight);
  font-size: var(--text-label-medium-size);
  line-height: var(--text-label-medium-line-height);
}

.labelSmall {
  font-weight: var(--text-label-weight);
  font-size: var(--text-label-small-size);
  line-height: var(--text-label-small-line-height);
}
