.copyButton {
  background: var(--colors-purple-lighter);
  color: var(--colors-purple-medium);
  border-radius: var(--defaults-borderRadius-small);
  border: 0;
  padding: 8px 12px;
  text-decoration: none;
  white-space: nowrap;
  font-weight: bold;
  display: flex;
  align-items: center;
  position: absolute;
  right: var(--mod-2-multiplier);
  top: calc(50% - 16px);
}
.copiedTextWrapper {
  margin-left: 5px;
}
.input {
  position: relative;
  margin: 10px 0;
}
.iconContainer {
  position: absolute;
  left: var(--mod-2-multiplier);
  top: calc(50% - var(--mod-1-multiplier));
}
.inputClass {
  padding: 0 var(--mod-2-multiplier) 0 var(--mod-2-multiplier);
  background: var(--background-strong);
  border-radius: var(--defaults-borderRadius-small);
  border: 2px solid var(--colors-grey-light);
  height: var(--mod-7-multiplier);
  font-size: var(--text-paragraph-medium-size);
  width: 100%;
}
.input:hover .inputClass {
  border-color: var(--colors-purple-light);
}
.inputClass:focus {
  border-color: var(--colors-purple-medium);
}
.inputClass:-webkit-autofill,
.inputClass:-webkit-autofill:hover,
.inputClass:-webkit-autofill:focus,
.inputClass:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.selectable {
  cursor: pointer;
}

/*  */

.allowEditingContent {
  display: flex;
  align-items: center;
}
.allowEditingText {
  margin-left: 5px;
  font-weight: bold;
  color: var(--colors-purple-dark);
}
.allowEditingDescriptionText {
  margin-left: 39px;
}
.collaboratorList {
  list-style: none;
  color: 'black';
  margin-left: 0;
}
.collaboratorsText {
  margin-bottom: 7px;
}
.collaboratorsContent {
  margin-top: 20px;
}
.disableSharingButton {
  background-color: transparent;
  border: none;
  font-weight: bold;
  color: var(--colors-red-medium);
  cursor: pointer;
  font-size: 12px;
}
.disableSharingWrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.shareTitleLeft {
  text-align: left;
  font-weight: bold;
  margin-bottom: 15px;
}
.shareTitleCenter {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 21px;
}
.sharingOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.shareModalContent {
  font-size: 12px;
}
.modalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
}
.shareListButton {
  background: transparent;
  border: 0;
  color: var(--brand-light);
  cursor: pointer;
}
.shareListText {
  margin-left: 5px;
  color: var(--colors-white);
}
.shareLinkMessage {
  margin-bottom: 30px;
  font-size: 14px;
}
.wrapper {
  min-height: 40px;
  padding: var(--mod-0-point-5-multiplier);
  padding-left: var(--mod-1-multiplier);
  padding-right: var(--mod-1-multiplier);
  margin-right: var(--mod-0-point-5-multiplier);
  cursor: pointer;
  border-radius: var(--defaults-borderRadius-small);
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: relative;
  background-color: var(--colors-grey-darker2);
}
.shareListModalWhenPrivate {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 104px;
  font-size: 14px;
}
.boldText {
  font-weight: bold;
}
.sliderStyling {
  -webkit-appearance: none;
  color: var(--colors-purple-medium);
  border-radius: 42px;
  width: 30px;
  padding: 2px 2px;
}
.sliderStyling::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 12px;
  height: 12px;
  background: var(--colors-white);
  border-radius: 6px;
  cursor: pointer;
}
.sliderStyling::-moz-range-thumb {
  width: 12px;
  height: 12px;
  background: var(--colors-white);
  border-radius: 6px;
  cursor: pointer;
}
.wrapper:hover:not(.selected),
.wrapper:focus:not(.selected) {
  background-color: var(--colors-grey-darker);
}
.li {
  padding-top: 0;
}
@media (--screen-mobile-max) {
  .button {
    display: none;
  }
}

.sharingModalOverlay {
  background-color: rgba(22, 27, 29, 0.5);
}
.sharingModal {
  border-radius: 12px;
  padding: 30px 50px;
  box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.25), 0 1px 2px 0 rgba(0, 0, 0, 0.15);
  max-width: 620px;
}
.sharingModal ul {
  font-size: var(--text-paragraph-medium-size);
  color: var(--colors-greyish-purple);
  list-style: none;
  margin-bottom: 16px;
}
.sharingModal li {
  padding: 5px 0;
}
