.link:focus {
  box-shadow: 0 0 0 3px var(--colors-grey-darkest),
    0 0 0 5px var(--brand-default);
}
.container {
  display: flex;
  align-items: center;
}
.header {
  background: var(--colors-grey-darkest);
  padding: var(--mod-1-point-5-multiplier) var(--mod-2-multiplier);
}
.headerLink,
.headerLink:hover {
  color: var(--colors-white);
  text-decoration: none;
  margin-right: var(--mod-3-multiplier);
}
.headerLink:hover,
.headerIconButton:hover {
  opacity: 0.7;
}
.headerName {
  display: flex;
  align-items: center;
  margin-right: var(--mod-2-multiplier);
}
.headerLogo {
  width: var(--mod-3-multiplier);
  height: var(--mod-3-multiplier);
  margin-right: var(--mod-1-multiplier);
}
.headerNav {
  display: flex;
  align-items: center;
  margin-left: auto;
}
@media (max-width: 1400px) {
  .headerDescription {
    visibility: hidden;
    position: absolute;
    z-index: -1;
  }
}
@media (--screen-smallest-max) {
  .headerNav {
    display: none;
  }
}
@media (--screen-mobile-max) {
  .headerIconButton,
  .contactLink {
    display: none;
  }
}
