.authLink {
  text-align: center;
}

.authLink:focus {
  box-shadow: 0 0 0 3px var(--colors-grey-darkest),
    0 0 0 5px var(--brand-default);
}
.authLink:hover {
  opacity: 0.7;
  text-decoration: none;
}
.authLink.authButton {
  background: var(--colors-grey-darker);
  padding: 5px 10px 7px;
  border-radius: var(--defaults-borderRadius-small);
}

.link {
  margin-top: -2px;
  margin-left: 10px;
}

.emailLink {
  cursor: pointer;
}
