.citationLists {
  display: flex;
  flex-direction: row;
  color: var(--colors-white);
  font-size: var(--text-label-small-size);
  align-items: center;
}

.newCitationListIcon {
  display: block;
  margin-right: var(--mod-1-multiplier);
  width: var(--mod-4-multiplier);
  height: var(--mod-4-multiplier);
  padding: var(--mod-1-point-25-multiplier);
  background-color: var(--colors-grey-darker2);
  border-radius: 50%;
}

.newCitationList:hover span {
  color: var(--brand-default);
}

.newCitationList {
  border: 0;
  font-size: var(--text-label-small-size);
  background-color: transparent;
  color: var(--brand-light);
  padding: 8px;
  padding-top: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: var(--text-label-weight);
}

@media (--screen-mobile-max) {
  .citationLists {
    display: none;
  }
}